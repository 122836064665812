<template>
  <div class="home" style="margin: -30px; height: calc(100vh - 60px)">
    <div class="flex h-full">
      <div
        class="w-64 flex-shrink-0 p-3 border-r h-full bg-gray-50 overflow-y-auto"
      >
        <div class="mb-3">
          <p class="mb-1 text-xs text-gray-500">Поиск по номеру документа</p>
          <div class="flex">
            <el-input
              v-model="filters.basis"
              @keydown.enter.native="handleFiltersChange"
              size="small"
              clearable
              @clear="handleFiltersChange"
              placeholder="Номер документа"
              class="w-full mr-2"
            >
            </el-input>
            <el-button @click="handleFiltersChange" size="small"
              >Поиск</el-button
            >
          </div>
        </div>
        <div class="w-full mb-3">
          <p class="mb-1 text-xs text-gray-500">Тип</p>
          <el-select
            v-model="filters.type"
            @change="handleFiltersChange"
            size="small"
            clearable
            filterable
            placeholder="Выберите тип"
            class="w-full"
          >
            <el-option
              v-for="x in types"
              :key="x.value"
              :label="x.label"
              :value="x.value"
            ></el-option>
          </el-select>
        </div>
        <div class="w-full mb-3">
          <p class="mb-1 text-xs text-gray-500">Склад</p>
          <el-select
            v-model="filters.warehouse_id"
            @change="handleFiltersChange"
            size="small"
            clearable
            filterable
            placeholder="Склад"
            class="w-full"
          >
            <el-option
              v-for="x in warehouses"
              :key="x.id"
              :label="x.name"
              :value="x.id"
            ></el-option>
          </el-select>
        </div>
        <div class="w-full mb-3">
          <span class="mb-1 text-xs text-gray-500 mr-2">Требует проверку</span>
          <el-switch
            @change="handleFiltersChange"
            v-model="filters.goods_arrived"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </div>
        <div class="w-full mb-3">
          <p class="mb-1 text-xs text-gray-500">Состояние</p>
          <el-select
            v-model="filters.state"
            @change="handleFiltersChange"
            size="small"
            clearable
            filterable
            placeholder="Состояние"
            class="w-full"
          >
            <el-option
              v-for="x in states"
              :key="x.state"
              :label="x.state"
              :value="x.state"
            ></el-option>
          </el-select>
        </div>
        <div class="w-full mb-3">
          <p class="mb-1 text-xs text-gray-500">Статус</p>
          <el-select
            v-model="filters.status"
            @change="handleFiltersChange"
            size="small"
            clearable
            filterable
            placeholder="Статус"
            class="w-full"
          >
            <el-option
              v-for="x in statuses"
              :key="x.status"
              :label="x.status"
              :value="x.status"
            ></el-option>
          </el-select>
        </div>
        <div class="w-full mb-3">
          <p class="mb-1 text-xs text-gray-500">Магазин</p>
          <el-select
            v-model="filters.shop_id"
            @change="handleFiltersChange"
            size="small"
            clearable
            filterable
            placeholder="Магазин"
            class="w-full"
          >
            <el-option
              v-for="x in shops"
              :key="x.id"
              :label="x.company"
              :value="x.id"
            ></el-option>
          </el-select>
        </div>
        <div class="w-full mb-3">
          <p class="mb-1 text-xs text-gray-500">Канал продаж</p>
          <el-select
            v-model="filters.channel_id"
            @change="handleFiltersChange"
            size="small"
            clearable
            filterable
            placeholder="Выберите канал продаж"
            class="w-full"
          >
            <el-option
              v-for="x in channels"
              :key="x.id"
              :label="x.name"
              :value="x.id"
            ></el-option>
          </el-select>
        </div>
        <div class="w-full">
          <p class="mb-1 text-xs text-gray-500">Дата</p>
          <el-date-picker
            style="width: 100%"
            v-model="filterDate"
            @change="handleFiltersChange"
            type="daterange"
            size="small"
            range-separator=" - "
            start-placeholder="Дата с"
            end-placeholder="Дата по"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="w-full h-full overflow-y-auto p-5">
        <template v-if="products && products.length">
          <h1
            class="font-semibold text-lg flex justify-between items-center mb-3"
          >
            Необходимо закупить
          </h1>
          <el-table border size="small" :data="products" class="mb-6">
            <el-table-column
              align="center"
              type="index"
              width="50"
              label="N"
              :index="indexMethod"
            ></el-table-column>
            <el-table-column width="150px" label="Склад" prop="warehouse_name">
            </el-table-column>
            <el-table-column label="Название">
              <template slot-scope="scope">
                [<router-link
                  target="_blank"
                  class="text-blue-500"
                  :to="{ name: 'Product', params: { id: scope.row.id } }"
                  >{{ scope.row.id }}</router-link
                >] - {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column label="Кол-во" prop="count"> </el-table-column>
          </el-table>
        </template>

        <template v-if="cancelled_accountings && cancelled_accountings.length">
          <h1
            class="font-semibold text-lg flex justify-between items-center mb-3"
          >
            Отмененные заказы
          </h1>
          <el-table
            border
            size="small"
            :data="cancelled_accountings"
            class="mb-6"
          >
            <el-table-column
              align="center"
              type="index"
              width="50"
              label="N"
              :index="indexMethod"
            ></el-table-column>
            <el-table-column width="150px" label="Склад" prop="warehouse_name">
            </el-table-column>
            <el-table-column label="Базис">
              <template slot-scope="scope">
                <router-link
                  target="_blank"
                  class="text-blue-500"
                  :to="{
                    name: 'accounting-item',
                    params: { id: scope.row.id },
                    query: { type: 'outcome' },
                  }"
                  >{{ scope.row.basis }}</router-link
                >
              </template>
            </el-table-column>
          </el-table>
        </template>

        <h1
          class="font-semibold text-lg flex justify-between items-center my-3"
        >
          Складской учет ({{ filters.total }})
          <div class="flex items-center">
            <router-link
              class="mr-2"
              :to="{
                name: 'accounting-item',
                params: { id: 'new' },
                query: { type: 'income' },
              }"
            >
              <el-button
                size="small"
                plain
                type="success"
                round
                icon="el-icon-plus"
                >Приход</el-button
              >
            </router-link>
            <router-link
              :to="{
                name: 'accounting-item',
                params: { id: 'new' },
                query: { type: 'outcome' },
              }"
            >
              <el-button
                size="small"
                plain
                type="warning"
                round
                icon="el-icon-plus"
                >Уход</el-button
              >
            </router-link>
          </div>
        </h1>
        <el-table
          border
          size="small"
          v-loading="loading"
          :data="data"
          stripe
          style="width: 100%"
        >
          <el-table-column
            align="center"
            type="index"
            width="50"
            label="N"
            :index="indexMethod"
          >
          </el-table-column>
          <!--          <el-table-column align="center" width="50" label="ID" prop="id">-->
          <!--          </el-table-column>-->
          <el-table-column prop="warehouse_name" label="Склад">
            <template slot-scope="scope">
              {{ scope.row.warehouse_name }}
              <template v-if="scope.row.delivery_mode">
                <br />
                {{ scope.row.delivery_mode }}
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="Тип">
            <template v-slot="scope">
              <i
                v-if="
                  problem_accounting_ids.includes(scope.row.id) ||
                  scope.row.pre_order > 0
                "
                class="el-icon-warning text-red-500 mr-2"
              ></i>

              <el-tag size="mini" :type="getStateOfAccounting(scope.row).type">
                {{ getStateOfAccounting(scope.row).label }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="channel_id" label="Канал продаж">
            <template v-slot="scope">
              {{ getChannelName(scope.row.channel_id) }}
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="Дата создания">
            <template v-slot="scope">
              {{ dateTimeFormat(scope.row.created_at2) }}
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="Дата обновления">
            <template v-slot="scope">
              {{ scope.row.updated_at && dateTimeFormat(scope.row.updated_at) }}
            </template>
          </el-table-column>
          <el-table-column prop="basis" label="Документ"></el-table-column>
          <el-table-column label="Сумма">
            <template v-slot="scope">
              {{ accountingSum(scope.row.products) }} тг
            </template>
          </el-table-column>
          <!--          <el-table-column-->
          <!--            prop="order_state"-->
          <!--            label="Состояние"-->
          <!--          ></el-table-column>-->
          <!--          <el-table-column prop="order_status" label="Статус"></el-table-column>-->
          <!--          <el-table-column-->
          <!--            prop="user_name"-->
          <!--            label="Пользователь"-->
          <!--          ></el-table-column>-->

          <!--          <el-table-column align="right" prop="brand" label="Магазин">-->
          <!--            <template slot-scope="scope">-->
          <!--              {{ getCompanyName(scope.row.shop_id) }}-->
          <!--            </template>-->
          <!--          </el-table-column>-->

          <el-table-column
            v-if="false"
            prop="user_name"
            label="Отзыв"
          ></el-table-column>
          <el-table-column align="right" prop="brand" label="Действия">
            <template slot-scope="scope">
              <!--              !scope.row.is_feedback_requested-->
              <el-button
                v-if="false"
                @click="() => openWhatsapp(scope.row.basis)"
                size="small"
                plain
                round
                type="warning"
                icon="el-icon-star-on"
                title="Запросить отзыв"
              ></el-button>

              <!--              v-if="[1, 2, 5].includes(scope.row.channel_id)"-->
              <el-button
                v-if="scope.row.type === 'outcome'"
                @click="() => downloadWaybill(scope.row.id)"
                size="small"
                plain
                round
                type="success"
                :icon="loadingWaybill ? 'el-icon-loading' : 'el-icon-printer'"
                title="Накладной"
                :disabled="loadingWaybill"
              ></el-button>

              <router-link
                class="ml-1"
                :to="{ name: 'accounting-item', params: { id: scope.row.id } }"
              >
                <el-button
                  size="small"
                  plain
                  round
                  type="primary"
                  icon="el-icon-view"
                ></el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="text-center my-5"
          background
          :disabled="loading"
          layout="prev, pager, next"
          :total="filters.total"
          :page-size="filters.limit"
          :current-page.sync="filters.page"
          @current-change="() => handleFiltersChange('page')"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getAccountings,
  getWarehouses,
  getChannels,
  getAccountingWaybill,
} from "@/api/accounting";
import { dateTimeFormat } from "@/helpers/formatter";
import { getShops } from "@/api/branch";
import { getOrderStatus } from "@/helpers/order-status";

export default {
  name: "Home",
  data: () => ({
    data: [],
    summary: [],
    types: [
      {
        label: "Приход",
        value: "income",
      },
      {
        label: "Уход",
        value: "outcome",
      },
    ],
    warehouses: [],
    channels: [],
    states: [],
    statuses: [],
    shops: [],
    products: [],
    problem_accounting_ids: [],
    cancelled_accountings: [],
    loading: false,
    loadingWaybill: false,
    filters: {
      basis: null,
      warehouse_id: null,
      channel_id: null,
      type: null,
      start_date: null,
      stop_date: null,
      state: null,
      status: null,
      shop_id: null,
      total: 0,
      pages_count: 0,
      page: 1,
      limit: 20,
      goods_arrived: 0,
    },
  }),
  computed: {
    filterDate: {
      get() {
        if (this.filters.start_date && this.filters.stop_date) {
          return [this.filters.start_date, this.filters.stop_date];
        }
        return [];
      },
      set(val) {
        if (val) {
          this.filters.start_date = val[0];
          this.filters.stop_date = val[1];
        } else {
          this.filters.start_date = null;
          this.filters.stop_date = null;
        }
      },
    },
  },
  async created() {
    this.warehouses = await getWarehouses();
    this.channels = await getChannels();
    this.shops = await getShops();
    this.parseUrlQuery();
    await this.getAccountings();
  },
  methods: {
    openWhatsapp(basis) {
      const text = `https://kaspi.kz/shop/review/productreview?orderCode=${basis}&productCode=yyyyyyyy&started_by=shop_order_details&rating=5`;
      window.open(
        "https://wa.me/77077110304?text=" + encodeURI(text),
        "_blank"
      );
    },
    async getAccountings() {
      this.loading = true;
      const result = await getAccountings(this.filters);
      const {
        data,
        pages_count,
        total,
        order_states,
        order_statuses,
        warehouses,
        start_date,
        stop_date,
      } = result;
      this.data = data;
      this.summary = result.analytics.sort((a, b) => b.sum - a.sum);
      this.filters.pages_count = pages_count;
      this.filters.total = total;
      this.filters.start_date = start_date;
      this.filters.stop_date = stop_date;
      this.states = order_states;
      this.statuses = order_statuses;
      this.problem_accounting_ids = result.problem_accounting_ids;
      this.cancelled_accountings = result.cancelled_accountings;

      this.products = [];
      for (const x in warehouses) {
        const warehouse = warehouses[x];
        for (const y in warehouse.products) {
          this.products.push({
            ...warehouse.products[y],
            warehouse_id: warehouse.warehouse_id,
            warehouse_name: warehouse.warehouse_name,
          });
        }
      }

      this.loading = false;
    },
    getChannelName(channelId) {
      const channel = this.channels.find((ch) => ch.id === channelId);
      return channel?.name;
    },
    accountingSum(products) {
      const sum = products.reduce((previousValue, currentValue) => {
        if (currentValue.dealer_price) {
          return previousValue + currentValue.count * currentValue.dealer_price;
        }
        return previousValue + currentValue.totalPrice;
      }, 0);

      return new Intl.NumberFormat("ru-RU").format(sum);
    },
    getStateOfAccounting(acc) {
      return getOrderStatus(acc);
    },
    changeUrlSearchParams() {
      this.$router.replace({
        query: {
          basis: this.filters.basis,
          type: this.filters.type,
          page: this.filters.page,
          warehouse_id: this.filters.warehouse_id,
          channel_id: this.filters.channel_id,
          start_date: this.filters.start_date,
          stop_date: this.filters.stop_date,
          state: this.filters.state,
          status: this.filters.status,
          shop_id: this.filters.shop_id,
          goods_arrived: this.filters.goods_arrived,
        },
      });
    },
    parseUrlQuery() {
      const {
        basis,
        page,
        warehouse_id,
        channel_id,
        type,
        start_date,
        stop_date,
        state,
        status,
        shop_id,
        goods_arrived,
      } = this.$route.query;
      this.filters.basis = basis;
      this.filters.page = page ? parseInt(page) : 1;
      this.filters.warehouse_id = warehouse_id ? parseInt(warehouse_id) : null;
      this.filters.channel_id = channel_id ? parseInt(channel_id) : null;
      this.filters.type = type;
      this.filters.start_date = start_date;
      this.filters.stop_date = stop_date;
      this.filters.state = state;
      this.filters.status = status;
      this.filters.shop_id = shop_id;
      this.filters.goods_arrived = goods_arrived ? +goods_arrived : 0;
    },
    async handleFiltersChange(param) {
      if (param !== "page") {
        this.filters.page = 1;
      }
      this.changeUrlSearchParams();
      await this.getAccountings();
    },
    indexMethod(index) {
      return index + 1 + (this.filters.page - 1) * this.filters.limit;
    },
    dateTimeFormat(timestamp) {
      return dateTimeFormat(timestamp * 1000);
    },
    numberFormat(number) {
      return new Intl.NumberFormat().format(number);
    },
    getCompanyName(shopId) {
      const shop = this.shops.find((s) => s.id === shopId);
      return (shop && shop.company) || shopId;
    },
    async downloadWaybill(accountingId) {
      this.loadingWaybill = true;
      const res = await getAccountingWaybill(accountingId);
      this.loadingWaybill = false;
      if (!res.success) return;
      window.open(`https://api.alasal.kz/waybills/${res.waybill}`);
    },
  },
};
</script>

<style scoped lang="scss">
.COMPLETED {
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
}

.CANCELLED {
  color: #f56c6c;
  background: #fef0f0;
  border-color: #fbc4c4;
}

.RETURNED {
  color: #909399;
  background: #f4f4f5;
  border-color: #d3d4d6;
}
</style>
